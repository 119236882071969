<template>
	<div>
		<pui-datatable :modelName="modelName" :actions="actions" :modelColumnDefs="modelColumnDefs"></pui-datatable>
		<customfields-modals></customfields-modals>
	</div>
</template>

<script>
import ShippositioningActions from './ShippositioningActions';

export default {
	name: 'shippositioning-grid',
	components: {},
	data() {
		return {
			modelName: 'shippositioning',
			actions: ShippositioningActions.gridactions,
			modelColumnDefs: {
				sts: {
					render: (data, type, row) => {
						return data ? `<i class="fas fa-check state-check"></i>` : '';
					}
				}
			}
		};
	}
};
</script>
